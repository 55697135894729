import React from "react";
import { CrumbProps } from "./Breadcrumbs.props";
import { Wrapper, CrumbLink } from "./Crumb.style";

const Crumb = (props: CrumbProps) => {
  const { children, link, isActive } = props;
  return (
    <Wrapper {...props}>
      {isActive ? (
        <CrumbLink style={{ color: "var(--color-red-light)" }} href={link}>
          {children}
        </CrumbLink>
      ) : (
        <CrumbLink href={link}>{children}</CrumbLink>
      )}
    </Wrapper>
  );
};

export default Crumb;
