import React, { useEffect, useRef, useState } from "react";
import GlobalStyles from "GlobalStyles";

import { StepperHeader, StepperHeaderMobile } from "components/StepperHeader";
import { Container } from "components/Container";
import { Question } from "components/Question";
import { Recommendation } from "components/Recommendation";

import { questions } from "./logic";
import { useSelector } from "react-redux";
import { IStepper, selectStepper } from "redux-components";
import { useActionCreators } from "hooks";

const App: React.FC = () => {
  const stepper: IStepper = useSelector(selectStepper);
  const [isMobile, setIsMobile] = useState(false);
  const { setRecommendationPage } = useActionCreators();
  const current = questions.find(
    (question) => question.id === stepper.activeQuestion.id
  );

  const contentRef = useRef<any>(null);

  const handleResize = () => {
    if (window.innerWidth < 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    // Send to parent iframe to get the height
    const message = JSON.stringify({
      iframeHeight: contentRef.current.offsetHeight,
    });
    window.parent.postMessage(message, "*");
  }, [contentRef]);

  return (
    <div style={{ padding: "2rem" }} ref={contentRef}>
      <header>
        {!stepper.isRecommendationPageActive && (
          <>
            {isMobile ? (
              <StepperHeaderMobile
                startRecommendation={stepper.isRecommendationPageActive}
              />
            ) : (
              <StepperHeader
                startRecommendation={stepper.isRecommendationPageActive}
              />
            )}
          </>
        )}
      </header>
      <Container>
        {stepper.isRecommendationPageActive ? (
          <Recommendation onPrev={() => setRecommendationPage(false)} />
        ) : current ? (
          <Question
            key={current.id}
            type={current.type}
            title={current.question}
            choices={current.choices}
          />
        ) : null}
      </Container>
      <GlobalStyles />
    </div>
  );
};

export default App;
