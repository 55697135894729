import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: var(--color-gray-light3);
  color: var(--color-gray-dark);
  padding: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 768px) {
    padding: 24px;
    border: 1px solid var(--color-gray-light1);
  }
`;
