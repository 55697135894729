import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { useActionCreators } from "hooks";
import { productsState, recommendationState } from "redux-components";
import Button from "components/base/Button/Button";
import { ArrowLeftOutline, Refresh } from "components/base/Icons";
import {
  getNonExistentProductIds,
  ProductsState,
} from "redux-components/reducers/productsReducer";
import { Product } from "components/Product";
import {
  Wrapper,
  RecommendationPageTitle,
  Actions,
} from "./Recommendation.styles";
import { Loader } from "components/base/Icons";
import { RecommendationState } from "redux-components/reducers/recommendationReducer";

interface IRecommendation {
  onPrev: () => void;
}

const Recommendation = (props: IRecommendation) => {
  const { onPrev } = props;
  const products: ProductsState = useSelector(productsState);
  const recommendation: RecommendationState = useSelector(recommendationState);
  const {
    resetStepper,
    resetProducts,
    resetRecommendation,
    setProductsToDisplay,
  } = useActionCreators();
  const [isMobile, setIsMobile] = useState(false);
  const contentRef = useRef<any>(null);

  const { fetchProducts } = useActionCreators();
  // Fetch the recommended products
  useEffect(() => {
    const productIds = getNonExistentProductIds(
      products.repo,
      recommendation.productIds
    );

    if (productIds && productIds.length) {
      const _fetchProducts = async () => {
        await setProductsToDisplay(false);
        await fetchProducts(productIds);
        setProductsToDisplay(recommendation.productIds);
      };
      _fetchProducts();
    } else {
      setProductsToDisplay(recommendation.productIds);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleReset = () => {
    resetStepper();
    resetProducts();
    resetRecommendation();
  };

  const handlePrev = async () => {
    onPrev();
  };

  useEffect(() => {
    // Send to parent iframe to get the height
    const message = JSON.stringify({
      iframeHeight: contentRef.current.offsetHeight + 100,
    });
    window.parent.postMessage(message, "*");
  });

  return (
    <Wrapper ref={contentRef}>
      {products.loading && !products.toDisplay.length ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "middle",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1>
            Please wait while we are looking for the best product(s) for you...
          </h1>
          <div style={{ margin: "60px 0px" }}>
            <Loader height={194} width={194} />
          </div>
        </div>
      ) : (
        <>
          <RecommendationPageTitle>
            Your Top {products.toDisplay.length} Recommended NORSK Gym Flooring
            Products
          </RecommendationPageTitle>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "3rem" }}
          >
            {products.toDisplay.map((product, index) => {
              return (
                <Product key={`item-${Math.random()}`} product={product} />
              );
            })}
          </div>
          <Actions>
            <Button onClick={handlePrev} variant="outlined">
              <div
                style={{
                  display: "flex",
                  gap: "4rem",
                  paddingRight: `${isMobile ? "unset" : "2rem"}`,
                  justifyContent: "center",
                }}
              >
                {!isMobile ? <ArrowLeftOutline /> : ""} Back{" "}
              </div>
            </Button>

            <Button onClick={handleReset}>
              <div
                style={{
                  display: "flex",
                  gap: "4rem",
                  paddingLeft: `${isMobile ? "unset" : "2rem"}`,
                  justifyContent: "center",
                }}
              >
                Start Over Again {!isMobile ? <Refresh /> : ""}
              </div>
            </Button>
          </Actions>
        </>
      )}
    </Wrapper>
  );
};

export default Recommendation;
