import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 50rem;
  grid-gap: 8px;
`;
export const ImgContainer = styled.div`
  height: 50px;
  width: 50px;
  cursor: pointer;
  padding: 2px;
  &:hover {
    border: 1px solid #afaeae;
  }
`;
