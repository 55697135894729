import { RankingCard } from 'components/base/RankingCard';
import { Wrapper } from './Ranking.styles';

type Choice = {
  id: string;
  value: string;
  imageLink?: string;
};

interface IRanking {
  choices: Choice[];
}

const Ranking = (props: IRanking) => {
  const { choices } = props;

  return (
    <Wrapper>
      {choices.map((item: Choice) => (
        <RankingCard
          key={`card-${item.id}`}
          id={item.id}
          label={item.value}
          imgUrl={item.imageLink}
        />
      ))}
    </Wrapper>
  );
};

export default Ranking;
