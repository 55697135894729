// ===================================================================================================================
// ==== HOOK THAT BINDS ACTION CREATORS TO DISPATCH SO WWE DONT NEED TO CALL DISPATCH EVERYTIME WE USE ACTION CREATORS

import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux-components"; 

export const useActionCreators = () => {
  const dispatch = useDispatch();

  // Bind all action creators to dispatch
  return bindActionCreators(actionCreators, dispatch);
};
