import React from 'react';

interface IconProps {
  height?: number;
  width?: number;
  color?: string;
}

const ArrowLeftOutline = (props: IconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color || 'currentColor'}
      viewBox="0 0 24 24"
      strokeWidth={2.5}
      stroke={color || 'currentColor'}
      width={width || 24}
      height={height || 24}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
      />
    </svg>
  );
};

export default ArrowLeftOutline;
