import React from 'react';

interface IconProps {
  height?: number;
  width?: number;
  color?: string;
}

const CheckboxOutline = (props: IconProps) => {
  const { width, height, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      stroke={color || 'currentColor'}
      width={width || 24}
      height={height || 24}
    >
      <path
        d="M9 42C8.2 42 7.5 41.7 6.9 41.1C6.3 40.5 6 39.8 6 39V9C6 8.2 6.3 7.5 6.9 6.9C7.5 6.3 8.2 6 9 6H39C39.8 6 40.5 6.3 41.1 6.9C41.7 7.5 42 8.2 42 9V39C42 39.8 41.7 40.5 41.1 41.1C40.5 41.7 39.8 42 39 42H9ZM9 39H39V9H9V39Z"
        fill={color}
      />
    </svg>
  );
};

export default CheckboxOutline;
