import produce from "immer";
import { ProductsActiontypes } from "redux-components/action-types";
import { ProductInterface } from "../interfaces";
import _ from "lodash";

// STATE INTERFACE
export interface ProductsState {
  loading: boolean;
  error: string | null;
  repo: ProductInterface[];
  toDisplay: ProductInterface[];
}

// INITIAL STATE
const initialState: ProductsState = {
  loading: false,
  error: null,
  repo: [],
  toDisplay: [],
};

export const getNonExistentProductIds = (
  storeProducts: ProductInterface[],
  productIds: string[]
) => {
  if (_.isEmpty(storeProducts) && _.isEmpty(productIds)) {
    return false;
  }

  const storeProductIds = storeProducts.map((product) => {
    // TODO: Make use of standard product object instead of using API property
    if (_.has(product, "SKUInfo.CatalogID")) {
      return `${product.SKUInfo.CatalogID}`;
    }

    if (_.has(product, "external.id")) {
      return `${product.external?.id}`;
    }

    return "";
  });

  const notInStoreProductIds = productIds.filter(
    (id) => !storeProductIds.includes(id)
  );

  return notInStoreProductIds;
};

// REDUCER
const productsReducer = produce(
  (state: ProductsState = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
      case ProductsActiontypes.FETCH: {
        state.loading = true;
        break;
      }

      case ProductsActiontypes.SET: {
        state.loading = false;
        state.repo.push(payload);
        break;
      }

      case ProductsActiontypes.SET_PRODUCTS_TO_DISPLAY: {
        if (!payload) {
          state.toDisplay = [];
        } else {
          const products = [];
          for (let i = 0; i < state.repo.length; i++) {
            if (_.has(state.repo[i], "external")) {
              products.push(state.repo[i]);
            } else {
              // TODO: Make use of standard product object instead of using API property
              if (payload.includes(`${state.repo[i].SKUInfo.CatalogID}`)) {
                products.push(state.repo[i]);
              }
            }
          }
          state.toDisplay = products;
        }

        break;
      }

      case ProductsActiontypes.ERROR: {
        state.loading = false;
        state.error = payload;
        break;
      }

      case ProductsActiontypes.RESET: {
        state.loading = initialState.loading;
        state.error = initialState.error;
        state.repo = initialState.repo;
        state.toDisplay = initialState.toDisplay;
        break;
      }
    }
  },
  initialState
);

export default productsReducer;
