import React, { ReactNode } from "react";
import { Wrapper } from "./Container.styles";

interface IContainer {
  children: ReactNode;
}

const Container = (props: IContainer) => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

export default Container;
