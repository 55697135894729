import {
  DualDensityReversibleGymFlooring,
  MatReversibleMultiPurpose,
  MultiUseFitnessMat,
  QExcerciseType,
  QFlooring,
  QPerformance,
  QRoomSize,
  QWeights,
  VersaRubberTechReversibleGymFlooring,
  VersaRubberTecSingleSided,
  VersaRubberTecTrulyReversibleWithRhinoTec,
} from "./defaults";

type TRecommendedProduct = {
  id: string;
  response: string[];
  recommendation: string[];
};

const recommendations: TRecommendedProduct[] = [
  {
    id: "1",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "2",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.tile,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "3",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "4",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "5",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "6",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      MultiUseFitnessMat,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "7",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      MultiUseFitnessMat,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "8",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      MultiUseFitnessMat,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "9",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      MultiUseFitnessMat,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "10",
    response: [
      QPerformance.comfort,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      MultiUseFitnessMat,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "11",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "12",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.tile,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "13",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "14",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "15",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "16",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "17",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "18",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "19",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "20",
    response: [
      QPerformance.comfort,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "21",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "22",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.tile,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "23",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "24",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "25",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "26",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "27",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.tile,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "28",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "29",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "30",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      DualDensityReversibleGymFlooring,
      VersaRubberTecSingleSided,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "31",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "32",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "33",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "34",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "35",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "36",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      MultiUseFitnessMat,
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "37",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.tile,
    ],
    recommendation: [
      MultiUseFitnessMat,
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "38",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      MultiUseFitnessMat,
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "39",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      MultiUseFitnessMat,
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "40",
    response: [
      QPerformance.comfort,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      MultiUseFitnessMat,
      MatReversibleMultiPurpose,
      DualDensityReversibleGymFlooring,
    ],
  },
  {
    id: "41",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "42",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "43",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "44",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "45",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "46",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "47",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "48",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "49",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "50",
    response: [
      QPerformance.desity,
      QExcerciseType.generalFitness,
      QRoomSize.partialRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "51",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "52",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "53",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "54",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "55",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "56",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MultiUseFitnessMat,
    ],
  },
  {
    id: "57",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MultiUseFitnessMat,
    ],
  },
  {
    id: "58",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MultiUseFitnessMat,
    ],
  },
  {
    id: "59",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MultiUseFitnessMat,
    ],
  },
  {
    id: "60",
    response: [
      QPerformance.desity,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MultiUseFitnessMat,
    ],
  },
  {
    id: "61",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "62",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "63",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "64",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "65",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "66",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "67",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "68",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "69",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "70",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "71",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "72",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "73",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "74",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "75",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "76",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "77",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "78",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "79",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "80",
    response: [
      QPerformance.desity,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "81",
    response: [
      QPerformance.durability,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "82",
    response: [
      QPerformance.durability,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "83",
    response: [
      QPerformance.durability,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "84",
    response: [
      QPerformance.durability,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "85",
    response: [
      QPerformance.durability,
      QExcerciseType.generalFitness,
      QRoomSize.entireRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTecSingleSided,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTechReversibleGymFlooring,
    ],
  },
  {
    id: "86",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "87",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "88",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "89",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "90",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      MatReversibleMultiPurpose,
    ],
  },
  {
    id: "91",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "92",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "93",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "94",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "95",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.entireRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "96",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "97",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "98",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "99",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "100",
    response: [
      QPerformance.durability,
      QExcerciseType.excerciseEquipment,
      QRoomSize.partialRoom,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "101",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "102",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "103",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "104",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "105",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.heavyWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "106",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "107",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.tile,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "108",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "109",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "110",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.entireRoom,
      QWeights.lightWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
      VersaRubberTecSingleSided,
    ],
  },
  {
    id: "111",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "112",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "113",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "114",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "115",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.heavyWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "116",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.hardwood,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "117",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.tile,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "118",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.carpet,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "119",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.vinyl,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
  {
    id: "120",
    response: [
      QPerformance.durability,
      QExcerciseType.freeWeights,
      QRoomSize.partialRoom,
      QWeights.lightWeights,
      QFlooring.concrete,
    ],
    recommendation: [
      MatReversibleMultiPurpose,
      VersaRubberTechReversibleGymFlooring,
      VersaRubberTecTrulyReversibleWithRhinoTec,
    ],
  },
];

export default recommendations;
