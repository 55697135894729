import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 3rem;
  width: 100%;
  gap: 4rem;
  background: #ffffff;
  border: 1px solid #b4b4bb;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const ProductImg = styled.img`
  height: 150px;
  width: 150px;

  @media (min-width: 768px) {
    height: 250px;
    width: 250px;
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const ProductOptions = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const TitleWrapper = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    align-items: flex-start;
  }
`;

export const Title = styled.h2`
  margin-bottom: 1rem;
  line-height: 120%;
  text-align: center;
  font-size: 2rem;

  @media (min-width: 768px) {
    font-size: 2.6rem;
  }

  @media (min-width: 1024px) {
    text-align: unset;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  font-size: 1.6rem;
`;

export const Price = styled.h4`
  font-weight: 'bold';
`;

export const DetailSectionTitle = styled.h4`
  margin-bottom: 1rem;
  font-size: 14px;
`;

export const PerformanceWrapper = styled.div`
  display: flex;
  gap: 4rem;
`;

export const PerformanceItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  h5 {
    margin-top: 5px;
  }
`;

export const RecommendationList = styled.ul`
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  margin-bottom: 1.6rem;
  font-size: 14px;
`;

export const RecommendationItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const RecommendationMoreDetails = styled.div`
  margin-left: 2rem;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const QuantityWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

export const QuantityTextBox = styled.input`
  text-align: center;
  height: 5rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const AddCartWrapper = styled.div`
  height: 5rem;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    font-size: 2.6rem;
    justify-content: flex-start;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ButtonIcon = styled.div`
  display: flex;
  gap: 1.2rem;
  width: 100%;
  justify-content: center;

  @media (min-width: 768px) {
    width: 180px;
  }
`;
