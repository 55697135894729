import { Dispatch } from "redux";
import { StepperDispatchTypes } from "redux-components/action-dispatch-types/StepperDispatchTypes";
import { StepperActiontypes } from "redux-components/action-types";

export const setStep =
  (id: string) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.SET,
      payload: id,
    });
  };

export const setAnswer =
  (id: string) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.SELECT_ANSWER,
      payload: {
        id,
      },
    });
  };

export const removeAnswer =
  (id: string) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.REMOVE_ANSWER,
      payload: {
        id,
      },
    });
  };

export const clearAnswers =
  (activeQuestionId: string) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.CLEAR_ANSWERS,
      payload: {
        activeQuestion: {
          id: activeQuestionId,
          done: false,
        },
        selectedAnswers: [],
        skippedQuestions: [],
        isRecommendationPageActive: false,
      },
    });
  };

export const setRanking =
  (data: { id: string; name: string; questionId: string }) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    const { id, name, questionId } = data;
    dispatch({
      type: StepperActiontypes.SET_RANK,
      payload: {
        id,
        name,
        questionId,
      },
    });
  };

export const resetRanking =
  (options: { id: string; questionId: string }) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.RESET_RANK,
      payload: options,
    });
  };

export const computeRanking =
  (questionId: string) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.COMPUTE_RANK,
      payload: questionId,
    });
  };

export const setRecommendationPage =
  (value: boolean) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.SET_RECOMMENDATION_PAGE,
      payload: value,
    });
  };

export const resetStepper =
  () => async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.RESET,
    });
  };

export const computeAnswers =
  (selectedAnswers: string[]) =>
  async (dispatch: Dispatch<StepperDispatchTypes>, getState: any) => {
    dispatch({
      type: StepperActiontypes.COMPUTE_ANSWERS,
      payload: selectedAnswers,
    });
  };
