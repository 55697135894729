import produce from "immer";
import { StepperActiontypes } from "redux-components/action-types";
import { IStepper, QuestionTypes, Ranking } from "redux-components/interfaces";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { questions } from "../../logic";

import { ranking as rankingSource } from "../../logic";

import { getNumberFromOrdinal, getNumberWithOrdinal } from "helpers";

const rankTypeQuestions = questions.filter(
  (q) => q.type === QuestionTypes.RANKING
);

const initialRankingState = rankTypeQuestions.map((q, index) => {
  return {
    questionId: q.id,
    items: q.choices.map((c) => {
      return {
        id: c.id,
        selected: false,
        options: q.choices.map((c, index) => {
          return {
            id: uuidv4(),
            name: `${getNumberWithOrdinal(index + 1)} rank`,
            selected: false,
          };
        }),
      };
    }),
  };
});

const initialState: IStepper = {
  activeQuestion: {
    id: questions[0].id,
    done: false,
  },
  selectedAnswers: [],
  skippedQuestions: [],
  ranking: initialRankingState,
  isRecommendationPageActive: false,
};

const findNextId = (id: string) => {
  const index = _.findIndex(questions, (q) => q.id === id) + 1;
  const nextIndex = index >= questions.length ? questions.length - 1 : index;
  const nextId = index === nextIndex ? questions[nextIndex].id : "";

  return nextId;
};

const findPrevId = (id: string) => {
  const index = _.findIndex(questions, (q) => q.id === id) - 1;
  const prevIndex = index <= 0 ? 0 : index;
  let prevId = questions[prevIndex].id;

  return prevId;
};

export const getNextQuestionId = (options: {
  id: string;
  skippedQuestions: string[];
}): string | undefined => {
  const { id, skippedQuestions } = options;
  let nextId = findNextId(id);

  // Move to next step if the current step is skipped
  if (skippedQuestions.includes(nextId)) {
    nextId = findNextId(nextId);
  }

  return nextId;
};

export const getPrevQuestionId = (options: {
  id: string;
  skippedQuestions: string[];
}): string => {
  const { id, skippedQuestions } = options;
  let prevId = findPrevId(id);

  // Move to prev step if the current step is skipped
  if (skippedQuestions.includes(prevId)) {
    prevId = findPrevId(prevId);
  }

  return prevId;
};

const computeRank = (state: IStepper, payload: any): Ranking[] => {
  const currentItem =
    state.ranking?.filter((r) => r.questionId === payload.questionId) ?? [];

  const otherRankingQuestions =
    state.ranking?.filter((r) => r.questionId !== payload.questionId) ?? [];

  const computedRankingItem = currentItem.map((choice) => {
    const currentItem = choice.items
      .filter(
        (item) =>
          // Get the current answered item for the question
          item.id === payload.id
      )
      .map((item) => {
        return {
          ...item,
          // Set the current answered item to selected
          selected: true,
        };
      })[0];

    const currentOptions = currentItem.options.map((option) => {
      if (option.name === payload.name) {
        return {
          ...option,
          selected: true,
        };
      } else {
        return option;
      }
    });

    const items = choice.items.map((item) => {
      if (item.id === currentItem.id) {
        return {
          id: item.id,
          options: currentOptions,
          selected: currentItem.selected,
        };
      } else {
        return item;
      }
    });

    return {
      ...choice,
      items: items,
    };
  });

  return [...otherRankingQuestions, ...computedRankingItem];
};

const resetRanking = (
  state: IStepper,
  option: { id: string; questionId: string }
) => {
  const { id, questionId } = option;
  const currentItem =
    state.ranking?.filter((r) => r.questionId === questionId) ?? [];

  const otherRankingQuestions =
    state.ranking?.filter((r) => r.questionId !== questionId) ?? [];

  const resettedRankingItem = currentItem.map((choice) => {
    const currentItem = choice.items
      .filter(
        (item) =>
          // Get the current answered item for the question
          item.id === id
      )
      .map((item) => {
        return {
          ...item,
          // Set the current answered item to unselected
          selected: false,
        };
      })[0];

    const currentOptions = currentItem.options.map((option) => {
      return {
        ...option,
        selected: false,
      };
    });

    const items = choice.items.map((item) => {
      if (item.id === currentItem.id) {
        return {
          id: item.id,
          options: currentOptions,
          selected: currentItem.selected,
        };
      } else {
        return item;
      }
    });

    return {
      ...choice,
      items: items,
    };
  });

  return [...resettedRankingItem, ...otherRankingQuestions];
};

const getRankResult = (state: IStepper, payload: any) => {
  const finishedQuestion = state.ranking?.filter((r) => {
    const allChoices = r.items;
    const answeredChoices = r.items.map((item) => item.selected);

    return (
      allChoices.length === answeredChoices.length && r.questionId === payload
    );
  });

  // get rank result from the finished question
  if (typeof finishedQuestion !== "undefined") {
    const _answers = finishedQuestion[0].items.map((item) => {
      const selected = item.options.filter((option) => option.selected)[0];

      return {
        id: item.id,
        rank: getNumberFromOrdinal(selected.name),
      };
    });

    const answers = _answers
      .sort((a, b) => a.rank.localeCompare(b.rank))
      .map((answer) => answer.id);

    // Find rank match in rank source
    const current = rankingSource.filter((rank) => rank.questionId === payload);
    const rankId = current
      .filter((c) => _.isEqual(c.ranking, answers))
      .map((r) => r.id);

    // Return the first recommendation for now with default recommendation
    return rankId[0] ? rankId[0] : "1b93994b-36ad-4abe-b234-3ef95ceda54d";
  }
};

export const sortSelectedAnswers = (selectedAnswers: string[]) => {
  const _selectedAnswers = selectedAnswers.map((id) => {
    let _index;
    questions.forEach((question, index) => {
      const questionChoicesIds = question.choices.map((q) => q.id);
      if (questionChoicesIds.includes(id)) {
        _index = index;
      }
    });

    return {
      id,
      index: _index,
    };
  });

  return _.sortBy(_selectedAnswers, [
    function (a) {
      return a.index;
    },
  ]).map((a) => a.id);
};

export const computeAnsweredQuestions = (
  selectedAnswers: string[]
): {
  totalQuestions: number;
  answeredQuestions: string[];
  skippedQuestions: string[];
} => {
  const totalQuestions = questions.length;
  const answeredQuestions = selectedAnswers;
  const skippedQuestions: string[] = [];

  questions.forEach((question) => {
    const isQuestionSkipped = !_.isEmpty(
      question.hideQuestionCriteria.find((criteria) => {
        const criteriaSize = criteria.length;
        const slicedAnswers = selectedAnswers.length
          ? selectedAnswers.slice(0, criteriaSize)
          : [];

        if (criteriaSize === slicedAnswers.length) {
          return _.isEqual(slicedAnswers, criteria);
        } else {
          return false;
        }
      })
    );
    if (isQuestionSkipped) {
      skippedQuestions.push(question.id);
    }
  });

  return {
    totalQuestions,
    answeredQuestions,
    skippedQuestions,
  };
};

export const isAnswered = (id: string, state: IStepper) => {
  const question = questions.filter((question) => question.id === id)[0];
  const choices = question.choices.map((c) => c.id).sort();
  const selectAnswers = state.selectedAnswers.map((i) => i).sort();

  let hasAnswer = false;

  for (let i = 0; i < selectAnswers.length; i++) {
    if (choices.includes(selectAnswers[i])) {
      hasAnswer = true;
    }
  }

  return hasAnswer;
};

const stepperReducer = produce(
  (state: IStepper = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
      case StepperActiontypes.SET: {
        const isLast = questions[questions.length - 1].id === payload;
        const isFirst = questions[0].id === payload;
        state.activeQuestion.id = payload;
        state.activeQuestion.done = isAnswered(payload, state);
        state.activeQuestion.isFirst = isFirst;
        state.activeQuestion.isLast = isLast;
        break;
      }
      case StepperActiontypes.SELECT_ANSWER: {
        state.selectedAnswers.push(payload.id);
        state.selectedAnswers = sortSelectedAnswers(state.selectedAnswers);

        break;
      }
      case StepperActiontypes.COMPUTE_ANSWERS: {
        const { skippedQuestions } = computeAnsweredQuestions(
          state.selectedAnswers
        );
        state.skippedQuestions = skippedQuestions;
        break;
      }
      case StepperActiontypes.CLEAR_ANSWERS: {
        const currentQuestion = questions.filter(
          (q) => q.id === payload.activeQuestion.id
        );
        const currentChoices = currentQuestion[0].choices.map((c) => c.id);
        let _currentSelectedAnswers = state.selectedAnswers;

        // clear first the selected answers from the current question
        // making sure the user will only choose one answer
        for (let i = 0; i < currentChoices.length; i++) {
          _currentSelectedAnswers = _currentSelectedAnswers.filter(
            (s) => s !== currentChoices[i]
          );
        }

        state.selectedAnswers = _currentSelectedAnswers;
        break;
      }

      case StepperActiontypes.REMOVE_ANSWER: {
        state.selectedAnswers = state.selectedAnswers.filter(
          (i) => i !== payload.id
        );
        break;
      }

      case StepperActiontypes.SET_RANK: {
        state.ranking = computeRank(state, payload);
        break;
      }

      case StepperActiontypes.RESET_RANK: {
        state.ranking = resetRanking(state, payload);
        break;
      }

      case StepperActiontypes.COMPUTE_RANK: {
        const recommendation = getRankResult(state, payload);

        if (
          typeof recommendation !== "undefined" &&
          !state.selectedAnswers.includes(recommendation)
        ) {
          state.selectedAnswers.push(recommendation);
        }

        break;
      }

      case StepperActiontypes.SET_RECOMMENDATION_PAGE: {
        state.isRecommendationPageActive = payload;
        break;
      }

      case StepperActiontypes.RESET: {
        state.selectedAnswers = initialState.selectedAnswers;
        state.activeQuestion = initialState.activeQuestion;
        state.ranking = initialState.ranking;
        state.isRecommendationPageActive =
          initialState.isRecommendationPageActive;
        break;
      }
    }
  },
  initialState
);

export default stepperReducer;
