import comfortImage from "../assets/images/comfort.png";
import densityImage from "../assets/images/density_2.png";
import durabilityImage from "../assets/images/durability_2.png";
import generalFitnessImage from "../assets/images/general_fitness.png";
import excerciseEquipmentImage from "../assets/images/exercise_equipment.png";
import freeWeightsImage from "../assets/images/free_weight.png";
import entireRoomImage from "../assets/images/entire_room_4.png";
import partialRoomImage from "../assets/images/partial_room_2.png";
import heavyWeightsImage from "../assets/images/heavy_weight.png";
import lightWeightsImage from "../assets/images/light_weight_2.png";
import hardWoodImage from "../assets/images/hardwood_2.png";
import tileImage from "../assets/images/tile.png";
import carpetImage from "../assets/images/carpet_2.png";
import vinylImage from "../assets/images/vinyl.png";
import concretelImage from "../assets/images/concrete_2.png";

import {
  QExcerciseType,
  QFlooring,
  QPerformance,
  QRoomSize,
  QWeights,
} from "./defaults";

type TQuestions = {
  id: string;
  type: string;
  step: number;
  title: string;
  question: string;
  choices: TChoices[];
  hideQuestionCriteria: any[] | [];
};

type TChoices = {
  id: string;
  value: string;
  imageLink: string;
};

const questions: TQuestions[] = [
  {
    id: QPerformance.id,
    type: "radio",
    step: 1,
    title: "Performance",
    question: "Performance Metrics",
    choices: [
      {
        id: QPerformance.comfort,
        value: "Comfort",
        imageLink: comfortImage,
      },
      {
        id: QPerformance.desity,
        value: "Density",
        imageLink: densityImage,
      },
      {
        id: QPerformance.durability,
        value: "Durability",
        imageLink: durabilityImage,
      },
    ],
    hideQuestionCriteria: [],
  },
  {
    id: QExcerciseType.id,
    type: "radio",
    step: 2,
    title: "Exercise Type",
    question: "Applications - Exercise Type   ",
    choices: [
      {
        id: QExcerciseType.generalFitness,
        imageLink: generalFitnessImage,
        value: "General Fitness",
      },
      {
        id: QExcerciseType.excerciseEquipment,
        imageLink: excerciseEquipmentImage,
        value: "Exercise Equipment",
      },
      {
        id: QExcerciseType.freeWeights,
        imageLink: freeWeightsImage,
        value: "Free Weights",
      },
    ],
    hideQuestionCriteria: [],
  },
  {
    id: QRoomSize.id,
    type: "radio",
    step: 3,
    title: "Room Size",
    question: "Applications - Room Size",
    choices: [
      {
        id: QRoomSize.entireRoom,
        imageLink: entireRoomImage,
        value: "Entire Room",
      },
      {
        id: QRoomSize.partialRoom,
        imageLink: partialRoomImage,
        value: "Partial Room",
      },
    ],
    hideQuestionCriteria: [],
  },
  {
    id: QWeights.id,
    type: "radio",
    step: 4,
    title: "Weights",
    question: "Applications - 2nd Weights Question",
    choices: [
      {
        id: QWeights.heavyWeights,
        imageLink: heavyWeightsImage,
        value: "Heavy Weights",
      },
      {
        id: QWeights.lightWeights,
        imageLink: lightWeightsImage,
        value: "Light Weights",
      },
    ],
    hideQuestionCriteria: [
      [
        QPerformance.comfort,
        QExcerciseType.generalFitness,
        QRoomSize.entireRoom,
      ],
      [
        QPerformance.comfort,
        QExcerciseType.generalFitness,
        QRoomSize.partialRoom,
      ],
      [
        QPerformance.comfort,
        QExcerciseType.excerciseEquipment,
        QRoomSize.entireRoom,
      ],
      [
        QPerformance.comfort,
        QExcerciseType.excerciseEquipment,
        QRoomSize.partialRoom,
      ],
      [
        QPerformance.desity,
        QExcerciseType.generalFitness,
        QRoomSize.entireRoom,
      ],
      [
        QPerformance.desity,
        QExcerciseType.generalFitness,
        QRoomSize.partialRoom,
      ],
      [
        QPerformance.desity,
        QExcerciseType.excerciseEquipment,
        QRoomSize.entireRoom,
      ],
      [
        QPerformance.desity,
        QExcerciseType.excerciseEquipment,
        QRoomSize.partialRoom,
      ],
      [
        QPerformance.durability,
        QExcerciseType.generalFitness,
        QRoomSize.entireRoom,
      ],
      [
        QPerformance.durability,
        QExcerciseType.generalFitness,
        QRoomSize.partialRoom,
      ],
      [
        QPerformance.durability,
        QExcerciseType.excerciseEquipment,
        QRoomSize.entireRoom,
      ],
      [
        QPerformance.durability,
        QExcerciseType.excerciseEquipment,
        QRoomSize.partialRoom,
      ],
    ],
  },
  {
    id: QFlooring.id,
    type: "radio",
    step: 5,
    title: "Flooring",
    question: "What Kind of Flooring Will This be Placed On?",
    choices: [
      {
        id: QFlooring.hardwood,
        imageLink: hardWoodImage,
        value: "Hardwood",
      },
      {
        id: QFlooring.tile,
        imageLink: tileImage,
        value: "Tile",
      },
      {
        id: QFlooring.carpet,
        imageLink: carpetImage,
        value: "Carpet",
      },
      {
        id: QFlooring.vinyl,
        imageLink: vinylImage,
        value: "Vinyl",
      },
      {
        id: QFlooring.concrete,
        imageLink: concretelImage,
        value: "Concrete",
      },
    ],
    hideQuestionCriteria: [
      [
        "b58313f7-d794-45f5-8784-fe4498c40fa1",
        "1f208e62-b111-4b04-bf9c-26ec1c72d894",
      ],
      [
        "126a5627-932c-45af-8ac8-63b179de51b7",
        "99efe917-5ec8-41bc-b9b1-331e00f9fc88",
      ],
    ],
  },
];

export default questions;
