// PRODUCT ACTION TYPES
export enum ProductsActiontypes {
  FETCH = "product/fetch",
  ERROR = "product/error",
  SET = "product/set",
  ADD_TO_CART = "product/addToCart",
  RESET = "product/reset",
  SET_PRODUCTS_TO_DISPLAY = "product/setProductsToDisplay",
}

export enum StepperActiontypes {
  NEXT = "stepper/next",
  PREV = "stepper/prev",
  SET = "stepper/setStep",
  SELECT_ANSWER = "stepper/selectAnswer",
  COMPUTE_ANSWERS = "stepper/computeAnswers",
  REMOVE_ANSWER = "stepper/removeAnswer",
  CLEAR_ANSWERS = "stepper/clearAnswers",
  SET_RANK = "stepper/setRank",
  RESET_RANK = "stepper/resetRank",
  COMPUTE_RANK = "stepper/computeRank",
  RESET = "stepper/reset",
  SET_RECOMMENDATION_PAGE = "stepper/setRecommendationPage",
}

export enum RecommendationActionTypes {
  EVALUATE = "recommendation/evaluate",
  SET_PRODUCTS = "recommendation/setProducts",
  RESET = "recommendation/reset",
}
