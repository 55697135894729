import {
  TAdvancedOptionList,
  TOptionList,
  TOptionSetList,
} from "redux-components";
import { Wrapper, ImgContainer } from "./Variants.styles";

interface IVariants {
  optionDataSet: TAdvancedOptionList[];
  optionSetList: TOptionSetList[];
  selectedOption: (option: TAdvancedOptionList) => void;
}

export const getOptionsData = (id: string, optionSetList: TOptionSetList[]) => {
  // TODO: Check if there are more options in the set list
  return optionSetList[0].OptionList.filter(
    (option) => option.OptionID === parseInt(id)
  )[0];
};

const Variants = (props: IVariants) => {
  const { optionDataSet, optionSetList, selectedOption } = props;

  const handleClick = (optionData: TOptionList) => {
    const selected = optionDataSet.filter(
      (option) => parseInt(option.AdvancedOptionCode) === optionData.OptionID
    );
    selectedOption(selected[0]);
  };

  return (
    <Wrapper>
      {optionDataSet.map((option, index) => {
        const optionData = getOptionsData(
          option.AdvancedOptionCode,
          optionSetList
        );
        if (option.AdvancedOptionStock > 0) {
          return (
            <ImgContainer key={`img-${index}`}>
              <img
                onClick={() => handleClick(optionData)}
                src={`https://norsk-stor.com/${optionData.OptionImagePath}`}
                alt={optionData.OptionName}
              />
            </ImgContainer>
          );
        } else {
          return null;
        }
      })}
    </Wrapper>
  );
};

export default Variants;
