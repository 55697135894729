export const VersaRubberTecSingleSided = "341";
export const VersaRubberTecTrulyReversibleWithRhinoTec = "381";
export const DualDensityReversibleGymFlooring = "1-externalProduct";
export const MatReversibleMultiPurpose = "2-externalProduct";
export const MultiUseFitnessMat = "3-externalProduct";
export const VersaRubberTechReversibleGymFlooring = "4-externalProduct";

export const ExternalProducts = [
  {
    external: {
      id: DualDensityReversibleGymFlooring,
      name: "Dual Density Reversible Gym Flooring",
      image:
        "https://images.thdstatic.com/productImages/ae14d59b-92b2-4344-b5bd-80f7237d8eca/svn/blue-norsk-gym-floor-tiles-31203ddbc-64_600.jpg",
      productUrl:
        "https://www.homedepot.com/p/Norsk-Blue-Camo-25-in-x-25-in-x-0-55-in-Dual-Sided-Impact-Foam-Gym-Tile-17-35-sq-ft-31203DDBC/319615695",
      price: 49.97,
      size: 17.35,
    },
  },
  {
    external: {
      id: MatReversibleMultiPurpose,
      name: "Reversible Multi-Purpose Fitness/Equipment Mat",
      image:
        "https://images.thdstatic.com/productImages/38be4d7d-7162-48fd-bf31-233cd284c933/svn/black-and-gray-norsk-gym-floor-tiles-24325gcrf-64_600.jpg",
      productUrl:
        "https://www.homedepot.com/p/Norsk-Gray-Camo-72-in-W-x-36-in-L-Rubber-Multi-Purpose-Fitness-Equipment-Mat-18-sq-ft-24325GCRF/319615863",
      price: 69.97,
      size: 18,
    },
  },
  {
    external: {
      id: MultiUseFitnessMat,
      name: "Multi Use Fitness Mat",
      image:
        "https://images.thdstatic.com/productImages/440e1afc-2ba1-47fd-8530-89e38d03cefb/svn/blue-norsk-gym-floor-tiles-24329bc-64_600.jpg",
      productUrl:
        "https://www.homedepot.com/p/Norsk-Blue-Camo-72-in-x-24-in-Foam-Multi-Use-Fitness-Mat-12-sq-ft-24329BC/319615857",
      price: 29.97,
      size: 12,
    },
  },
  {
    external: {
      id: VersaRubberTechReversibleGymFlooring,
      name: "Versa Rubber-Tech Reversible Gym Flooring",
      image:
        "https://images.thdstatic.com/productImages/ac114cb6-275f-4827-8945-89dfbf5ea923/svn/black-gray-norsk-gym-floor-tiles-32102rtbw-64_600.jpg",
      productUrl:
        "https://www.homedepot.com/p/Norsk-Black-with-Blue-White-Flecks-20-5-in-x-20-5-in-x-0-47-in-Rubber-Tec-Reversible-Gym-Flooring-12-sq-ft-4-Pack-32102RTBW/319615950",
      price: 49.97,
      size: 12,
    },
  },
];

export const QPerformance = {
  id: "89781a4e-891e-485d-bd89-7cbcceeca9f6",
  comfort: "520c1149-f3d1-437a-ba76-706d2b0f8b79",
  desity: "e77570de-1c93-4ef3-a258-e137a04feb47",
  durability: "12a6cb78-c015-46b9-b35a-83aa8d1920ba",
};

export const QExcerciseType = {
  id: "a979e279-9e85-4433-a03e-99c0fbd2765e",
  generalFitness: "c136e63c-1c5f-4aaf-822a-a96fea6ffcf8",
  excerciseEquipment: "cb97e342-d556-4cbe-a94a-792608469c27",
  freeWeights: "ed19f9b0-22b8-49d4-b620-a0a5c4ef2fed",
};

export const QRoomSize = {
  id: "2089043a-869f-4b9c-af79-cf54680b9f30",
  entireRoom: "2a5c0898-37ad-490b-8553-036ed77d2241",
  partialRoom: "6d760bde-ef1f-4e0d-af7f-1392872dc3fb",
};

export const QWeights = {
  id: "4617b3b4-2dab-4c0d-ab98-384a0652a42a",
  heavyWeights: "ae9ea230-368d-4edc-bdfa-793d71f8c045",
  lightWeights: "86309883-5cd8-4714-a47f-1f55d381894f",
};

export const QFlooring = {
  id: "8860cae6-4cfc-4a92-9bc4-572b720d21b9",
  hardwood: "8b50fc1d-af05-42ae-9b87-72661dddfe9d",
  tile: "30a3f71d-3307-46e6-ad3c-65b91745fb17",
  carpet: "6dba1ee4-a5e4-4dc9-a5ff-94254050d257",
  vinyl: "5de5a29e-c9af-4a36-a56e-779f4cac59f2",
  concrete: "8f6ca5ad-7cc0-4f1b-a8e7-0a3d2d2de61f",
};
