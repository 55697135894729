import styled from 'styled-components';

interface Props {
  style?: any;
  btnWidth?: string;
}

export const Icon = styled.span`
  text-align: right;
`;

export const ButtonBase = styled.button<Props>`
  padding: var(--padding);
  font-size: var(--fontSize);
  border: none;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  cursor: pointer;
  width: ${(props) => (props.btnWidth ? props.btnWidth : 'unset')};

  &:focus {
    outline-color: var(--color-red-default);
    outline-offset: 0.1rem;
  }
  ${(props) =>
    props.disabled
      ? 'touch-action: none; pointer-events: none; opacity: 0.5'
      : ''};
`;

export const FillButton = styled(ButtonBase)<Props>`
  color: var(--color-neutral-white);
  background-color: ${(props) =>
    props.color ? props.color : 'var(--color-neutral-black)'};
  border: 1px solid transparent;

  &:hover {
    opacity: 0.9;
  }
`;

export const OutlineButton = styled(ButtonBase)`
  color: var(--color-neutral-black);
  background-color: var(--color-neutral-white);
  border: 0.1rem solid var(--color-neutral-black);

  &:hover {
    background-color: var(--color-gray-light1);
  }
`;

export const GhostButton = styled(ButtonBase)`
  background-color: var(--color-neutral-white);
  color: var(--color-neutral-black);
  border: 1px solid transparent;

  &:hover {
    background-color: var(--color-gray-light1);
  }
`;

export const IconButton = styled(ButtonBase)`
  background-color: var(--color-neutral-white);
  color: var(--color-neutral-black);
  border: 1px solid transparent;
  display: flex;

  &:hover {
    background-color: var(--color-gray-light1);
  }
`;
