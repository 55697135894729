import produce from "immer";
import { RecommendationActionTypes } from "redux-components/action-types";
import _ from "lodash";

import { recommendations } from "../../logic";

// STATE INTERFACE
export interface RecommendationState {
  productIds: string[];
}

interface IRecommendation {
  id: string;
  response: string[];
  recommendation: any;
}

// INITIAL STATE
const initialState: RecommendationState = {
  productIds: [],
};

const _recommendations: IRecommendation[] = recommendations;

const getRecommendations = (answers: string[]): string[] => {
  const productIds = [];
  for (let i = 0; i < _recommendations.length; i++) {
    if (
      _.isEqual(
        _.sortBy(_recommendations[i].response.map((a) => a)),
        _.sortBy(answers)
      )
    ) {
      const prodId = _recommendations[i].recommendation;
      productIds.push(prodId);
    }
  }

  return _.flattenDeep(productIds);
};

// REDUCER
const recommendationReducer = produce(
  (state: RecommendationState = initialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
      case RecommendationActionTypes.EVALUATE: {
        const productIds = getRecommendations(payload);
        // default answer is "116" if no found product(s) for recommendation
        state.productIds = productIds.length ? productIds : ["116"];
        break;
      }

      case RecommendationActionTypes.RESET: {
        state.productIds = initialState.productIds;
        break;
      }
    }
  },
  initialState
);

export default recommendationReducer;
