export interface IStepper {
  activeQuestion: {
    id: string;
    done: boolean;
    isFirst?: boolean;
    isLast?: boolean;
  };
  selectedAnswers: string[];
  skippedQuestions: string[];
  ranking?: Ranking[];
  isRecommendationPageActive: boolean;
}

export type Ranking = {
  questionId: string;
  items: RankingValues[];
};

export type Choice = {
  id: string;
  value: string;
  imageLink?: string;
};

export enum QuestionTypes {
  RADIO = "radio",
  RANKING = "ranking",
}

export type RankingOptions = {
  id: string;
  name: string;
  selected: boolean;
};

export type RankingValues = {
  id: string;
  selected: boolean;
  options: RankingOptions[];
};
