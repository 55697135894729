import React, { useEffect, useState } from 'react';
import Button from 'components/base/Button/Button';
import { ArrowRightOutline, ArrowLeftOutline } from 'components/base/Icons';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import _ from 'lodash';

import { animated, useSpring } from 'react-spring';

import { questions } from '../../logic';

import { useActionCreators } from 'hooks';
import { selectStepper } from 'redux-components/selectors/StepperSelector';
import { Choice, IStepper, QuestionTypes } from 'redux-components';

import {
  getNextQuestionId,
  getPrevQuestionId,
  isAnswered,
} from 'redux-components/reducers/stepperReducer';
import { Radio } from './Radio';
import { Ranking } from './Ranking';
import { Actions, Wrapper, QuestionTypeContainer } from './Question.styles';

interface IQuestion {
  type: QuestionTypes | string;
  title: string;
  choices: Choice[];
}

const Question = (props: IQuestion) => {
  const { title, choices, type } = props;
  const current: IStepper = useSelector(selectStepper);
  const selectedAnswers = current.selectedAnswers;
  const skippedQuestions = current.skippedQuestions;
  const { setStep, computeRanking, evaluateAnswers, setRecommendationPage } =
    useActionCreators();
  const [isMobile, setIsMobile] = useState(false);

  const animationProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
  });

  const handleNext = async () => {
    const id = getNextQuestionId({
      id: current.activeQuestion.id,
      skippedQuestions,
    });

    if (type === QuestionTypes.RANKING) {
      await computeRanking(current.activeQuestion.id);
    }

    if (id) {
      await setStep(id);
    } else {
      await evaluateAnswers(selectedAnswers);
      await setRecommendationPage(true);
    }
  };

  const handlePrev = async () => {
    const id = getPrevQuestionId({
      id: current.activeQuestion.id,
      skippedQuestions,
    });
    if (id.length) {
      await setStep(id);
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  const _isFinished = () => {
    if (type === QuestionTypes.RANKING) {
      const id = current.activeQuestion.id;
      const currentQuestion = current.ranking?.filter(
        (item) => item.questionId === id
      )[0];

      return (
        currentQuestion?.items.length ===
        currentQuestion?.items.filter((item) => item.selected).length
      );
    } else {
      return isAnswered(current.activeQuestion.id, current);
    }
  };

  const isFinished = _isFinished();

  return (
    <animated.div style={animationProps}>
      <Wrapper>
        <h1>{parse(title)}</h1>

        {/* ================ Cards */}
        <QuestionTypeContainer>
          {type === QuestionTypes.RADIO ? <Radio choices={choices} /> : null}
          {type === QuestionTypes.RANKING ? (
            <Ranking choices={choices} />
          ) : null}
        </QuestionTypeContainer>
        {/* ================ Actions */}
        <Actions>
          <Button
            disabled={
              _.isEmpty(selectedAnswers) || current.activeQuestion.isFirst
            }
            onClick={handlePrev}
            variant="outlined"
          >
            <div
              style={{
                display: 'flex',
                gap: '4rem',
                paddingRight: `${isMobile ? 'unset' : '2rem'}`,
                justifyContent: 'center',
              }}
            >
              {!isMobile ? <ArrowLeftOutline /> : ''} Back{' '}
            </div>
          </Button>

          <Button disabled={!isFinished} onClick={handleNext}>
            <div
              style={{
                display: 'flex',
                gap: '4rem',
                paddingLeft: `${isMobile ? 'unset' : '2rem'}`,
                justifyContent: 'center',
              }}
            >
              {current.activeQuestion.isLast ? 'Finish' : 'Next'}{' '}
              {!isMobile ? <ArrowRightOutline /> : ''}
            </div>
          </Button>
        </Actions>
      </Wrapper>
    </animated.div>
  );
};

export default Question;
