import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  align-items: center;
  gap: 4px;
  background: #f7f8f9;

  @media (min-width: 768px) {
    padding: 3rem;
  }
`;

export const RecommendationPageTitle = styled.h1`
  margin: 3rem;
  text-align: center;
  font-size: 2.6rem;

  @media (min-width: 768px) {
    font-size: 4rem;
    margin-top: 0;
    margin-bottom: 6rem;
    line-height: 124%;
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 4rem;

  @media (min-width: 480px) {
    flex-direction: row;
    gap: 4rem;
  }

  @media (min-width: 1550px) {
    width: 80%;
  }
`;
