import styled from 'styled-components';

export const Wrapper = styled.li`
  display: inline;

  &:not(:first-of-type) {
    @media (min-width: 1024px) {
    }

    &::before {
      content: ' › ';
      opacity: 0.4;
      margin-right: var(--space-sm);

      @media (min-width: 1024px) {
        margin-right: var(--space-md);
      }
    }
  }
`;

export const CrumbLink = styled.a`
  color: var(--color-gray-light2);
  font-weight: bold;
  text-decoration: none;
  font-size: 13px;

  &:hover {
    /* text-decoration: revert; */
    color: var(--color-gray-dark);
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
`;
