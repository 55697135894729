import React from 'react';
import { BreadcrumbsProps } from './Breadcrumbs.props';
import { Wrapper, BreadcrumbsList } from './Breadcrumbs.styles';

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { children } = props;

  return (
    <Wrapper aria-label="Breadcrumb">
      <BreadcrumbsList>{children}</BreadcrumbsList>
    </Wrapper>
  );
};

export default Breadcrumbs;
