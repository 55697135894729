import Button from 'components/base/Button/Button';
import { useState } from 'react';
import { decode } from 'html-entities';
import {
  ProductInterface,
  TAdvancedOptionList,
  TOptionList,
} from 'redux-components';
import _ from 'lodash';
import { Variants } from './Variants';
import { CheckOutline, CartOutline, Loading } from '../base/Icons';
import { getOptionsData } from './Variants/Variants';
import {
  Wrapper,
  ProductImg,
  ProductDetails,
  TitleWrapper,
  Title,
  PriceWrapper,
  Price,
  DetailSectionTitle,
  PerformanceWrapper,
  PerformanceItem,
  RecommendationList,
  RecommendationItem,
  RecommendationMoreDetails,
  QuantityWrapper,
  QuantityTextBox,
  AddCartWrapper,
  ButtonIcon,
  ProductOptions,
} from './Product.styles';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { CircularProgress } from 'components/base/CircularProgress';

import productHighlight from '../../assets/product-highlight.json';

interface IProduct {
  product: ProductInterface;
}

const Product = (props: IProduct) => {
  const { product } = props;
  const [selectedOption, setSelectedOption] =
    useState<TAdvancedOptionList | null>(
      _.has(product, 'AdvancedOptionList')
        ? product.AdvancedOptionList[0]
        : null
    );
  const [qty, setQty] = useState<number>(1);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [submit, setSubmit] = useState(false);

  // Render if product is external
  if (product.external) {
    return (
      <Wrapper>
        {imageLoaded ? null : (
          <Skeleton
            style={{
              width: 250,
              maxWidth: 250,
              height: 250,
            }}
          />
        )}
        <ProductImg
          src={product.external.image}
          style={{ display: imageLoaded ? 'block' : 'none' }}
          onLoad={() => setImageLoaded(true)}
          alt={product.external.name}
        />
        <ProductDetails>
          <TitleWrapper>
            <Title>{`${product.external.name}`}</Title>

            <PriceWrapper>
              <Price>${product.external.price}</Price>
              <span>|</span>
              <p>{product.external.size} / sq. ft</p>
            </PriceWrapper>
          </TitleWrapper>
          <AddCartWrapper>
            <a
              href={product.external.productUrl}
              target="_blank"
              rel="noreferrer"
              style={{ width: '100%' }}
            >
              <Button color="var(--color-red-default)" btnWidth="100%">
                View Product
              </Button>
            </a>
          </AddCartWrapper>
        </ProductDetails>
      </Wrapper>
    );
  }

  let imgSrc = '',
    _selectedOption: TOptionList;

  if (selectedOption) {
    _selectedOption = getOptionsData(
      selectedOption.AdvancedOptionCode,
      product.OptionSetList
    );
    imgSrc = `https://norsk-stor.com/${_selectedOption.OptionImagePath}`;
  } else {
    imgSrc = `https://norsk-stor.com/${product.MainImageFile}`;
  }

  const handleClick = () => {
    setSubmit(true);

    // Send data to parent iframe
    let data: any = {
      item_id: product.SKUInfo.CatalogID,
      itemid: product.SKUInfo.SKU,
      customPoints: '[customPoints]',
      pointsMultiplier: '[pointsMultiplier]',
      productRewardDesc: '[productRewardDesc]',
      qty,
    };

    if (product.OptionSetList.length) {
      const optionName = `option-di_${product.OptionSetList[0].OptionSetID}-${product.SKUInfo.CatalogID}`;
      data[optionName] = `${_selectedOption.OptionID}`;
      data.optionName = optionName;
      data.itemid_advancedoption =
        selectedOption && selectedOption.AdvancedOptionSufix;
    }

    const message = JSON.stringify(data);
    window.parent.postMessage(message, '*');
  };

  const handleSelectedOption = (option: TAdvancedOptionList) => {
    setSelectedOption(option);
    setQty(1);
  };

  const handleQtyChange = (e: any) => {
    if (product.OptionSetList.length) {
      if (
        selectedOption &&
        selectedOption.AdvancedOptionStock > e.target.value
      ) {
        setQty(e.target.value);
      } else {
        setQty(selectedOption ? selectedOption.AdvancedOptionStock : 0);
      }
    } else {
      if (product.SKUInfo.Stock > e.target.value) {
        setQty(e.target.value);
      } else {
        setQty(product.SKUInfo.Stock);
      }
    }
  };

  /*const highlights = _.get(
    productHighlight.filter((p) => p.id === `${product.SKUInfo.CatalogID}`)[0],
    "list",
    []
  );*/

  return (
    <Wrapper>
      {imageLoaded ? null : (
        <Skeleton
          style={{
            width: 250,
            maxWidth: 250,
            height: 250,
          }}
        />
      )}
      <ProductImg
        src={imgSrc}
        style={{ display: imageLoaded ? 'block' : 'none' }}
        onLoad={() => setImageLoaded(true)}
        alt={product.SKUInfo.Name}
      />
      <ProductDetails>
        <TitleWrapper>
          <Title>{decode(product.SKUInfo.Name)}</Title>

          <PriceWrapper>
            <Price>${product.SKUInfo.Price}</Price>
            <span>|</span>
            <p>{product.ExtraField1} / sq. ft</p>
          </PriceWrapper>
        </TitleWrapper>

        {product.OptionSetList.length ? (
          <ProductOptions>
            <Variants
              optionDataSet={product.AdvancedOptionList}
              optionSetList={product.OptionSetList}
              selectedOption={(selectedOption) =>
                handleSelectedOption(selectedOption)
              }
            />
          </ProductOptions>
        ) : (
          <></>
        )}

        {/* Performance Indicator */}
        {/*<div>
          <DetailSectionTitle>PERFORMANCE INDICATOR</DetailSectionTitle>
          <PerformanceWrapper>
            <PerformanceItem>
              <CircularProgress
                percentage={90}
                height={50}
                width={50}
                strokeWidth={5}
                percentageTextColor={"var(--color-gray-default)"}
                trailColor={"var(--color-red-default)"}
                pathColor={"var(--color-gray-light1)"}
              />
              <h5>Comfort</h5>
            </PerformanceItem>
            <PerformanceItem>
              <CircularProgress
                percentage={75}
                height={50}
                width={50}
                strokeWidth={5}
                percentageTextColor={"var(--color-gray-default)"}
                trailColor={"var(--color-red-default)"}
                pathColor={"var(--color-gray-light1)"}
              />
              <h5>Density</h5>
            </PerformanceItem>
            <PerformanceItem>
              <CircularProgress
                percentage={86}
                height={50}
                width={50}
                strokeWidth={5}
                percentageTextColor={"var(--color-gray-default)"}
                trailColor={"var(--color-red-default)"}
                pathColor={"var(--color-gray-light1)"}
              />
              <h5>Durability</h5>
            </PerformanceItem>
          </PerformanceWrapper>
        </div>*/}

        {/* Recommendation Highlight */}
        {/*<div>
          <DetailSectionTitle>RECOMMENDATION HIGHLIGHT</DetailSectionTitle>
          <RecommendationList>
            {highlights.map((list, index) => (
              <RecommendationItem key={`item-${index}`}>
                <CheckOutline color="var(--color-green-default)" />
                {list}
              </RecommendationItem>
            ))}
          </RecommendationList>
          <RecommendationMoreDetails
            onClick={() =>
              (window.location.href = `https://www.norsk-stor.com/product.asp?itemid=${product.SKUInfo.CatalogID}`)
            }
          >
            MORE DETAILS
          </RecommendationMoreDetails>
          </div>*/}

        {/* Quantity*/}
        <QuantityWrapper>
          <div style={{ width: '100%' }}>
            <DetailSectionTitle>QUANTITY</DetailSectionTitle>
            <QuantityTextBox
              value={qty}
              type="number"
              onChange={handleQtyChange}
              placeholder="Quantity"
            />
          </div>
          <AddCartWrapper>
            <Button
              onClick={handleClick}
              color="var(--color-red-default)"
              btnWidth="100%"
              disabled={submit}
            >
              <ButtonIcon>
                {submit ? (
                  <Loading color="#fff" height={20} width={23} />
                ) : (
                  <CartOutline />
                )}
                Add to cart
              </ButtonIcon>
            </Button>
          </AddCartWrapper>
        </QuantityWrapper>
      </ProductDetails>
    </Wrapper>
  );
};

export default Product;
