import { combineReducers } from "redux";
import ProductsReducer from "./productsReducer";
import StepperReducer from "./stepperReducer";
import RecommendationReducer from "./recommendationReducer";

const reducers = combineReducers({
  products: ProductsReducer,
  stepper: StepperReducer,
  recommendation: RecommendationReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
