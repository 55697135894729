import { useActionCreators } from 'hooks';
import { useSelector } from 'react-redux';
import { IStepper, selectStepper } from 'redux-components';
import { Dropdown } from '../Dropdown';
import Skeleton from 'react-loading-skeleton';

import {
  Card,
  CardHeader,
  CardHeaderLabel,
  CardImage,
} from './RankingCard.styles';
import { useState } from 'react';

interface IRankingCard {
  id: string; // this is the choice ID
  label?: string;
  imgUrl?: string;
  children?: React.ReactNode;
}

const RankingCard = (props: IRankingCard) => {
  const { id, label, imgUrl } = props;
  const { setRanking, resetRanking } = useActionCreators();
  const [imageLoaded, setImageLoaded] = useState(false);
  const stepper: IStepper = useSelector(selectStepper);
  const question =
    stepper.ranking?.filter(
      (question) => question.questionId === stepper.activeQuestion.id
    ) ?? [];

  // Get the options based from the id
  const options =
    question[0].items.filter((item) => item.id === id)[0].options ?? [];
  const isSelected = question[0].items.filter((item) => item.id === id)[0]
    .selected;

  const selectedOptions = question[0].items
    .map((item) => {
      const options = item.options.filter((option) => option.selected);
      return options;
    })
    .filter((o) => o.length)
    .map((option) => option[0].name);

  const questionId = question[0].questionId;

  const handleOnChange = (name: string) => {
    setRanking({
      id,
      name,
      questionId,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardHeaderLabel>{label}</CardHeaderLabel>
      </CardHeader>
      <Dropdown
        isSelected={isSelected}
        selectedOptions={selectedOptions}
        values={options}
        onChange={(value) => {
          if (!value) {
            resetRanking({
              id,
              questionId,
            });
          } else {
            handleOnChange(value);
          }
        }}
      />
      <CardImage>
        {imageLoaded ? null : (
          <Skeleton
            style={{
              width: 320,
              maxWidth: 320,
              height: 320,
            }}
          />
        )}

        <img
          style={{ display: imageLoaded ? 'block' : 'none', width: '100%' }}
          onLoad={() => setImageLoaded(true)}
          src={imgUrl}
          alt={id}
        />
      </CardImage>
    </Card>
  );
};

export default RankingCard;
