import { RankingOptions } from "redux-components";
import { Wrapper } from "./Dropdown.stlyes";

import Select, { StylesConfig } from "react-select";

interface IDropdown {
  values: RankingOptions[];
  onChange: (name: string | null) => void;
  isSelected: boolean;
  selectedOptions: string[];
}

const Dropdown = (props: IDropdown) => {
  const { values, onChange, isSelected, selectedOptions } = props;

  const colourStyles: StylesConfig<any, true> = {
    menu: (styles) => ({
      ...styles,
      display: options.filter((o) => o.selected).length
        ? "none !important"
        : "inherit",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      display: options.filter((o) => o.selected).length
        ? "none !important"
        : "inherit",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: options.filter((o) => o.selected).length
        ? "none !important"
        : "inherit",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      borderRadius: 0,
      border: "1px solid var(--color-neutral-black)",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid var(--color-neutral-black)",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        display: options.filter((o) => o.selected).length
          ? "none !important"
          : "inherit",
        backgroundColor: isSelected ? "var(--color-gray-light1)" : "#fff",
        color: isDisabled
          ? isSelected
            ? "var(--color-neutral-black)"
            : "var(--color-gray-light2)"
          : "var(--color-neutral-black)",
        cursor: isDisabled ? "default" : "pointer",
        ":disabled": {
          background: "red",
        },
        ":hover": {
          background: isDisabled
            ? isSelected
              ? "var(--color-gray-light1)"
              : "#fff"
            : "var(--color-gray-light1)",
        },
      };
    },
  };

  const handleChange = (e: any) => {
    if (e) {
      onChange(e.value);
    } else {
      onChange(null);
    }
  };

  const options = values.map((v, i) => {
    return {
      value: v.name,
      label: v.name,
      disabled: selectedOptions.includes(v.name) || isSelected,
      selected: v.selected,
    };
  });

  return (
    <Wrapper>
      <Select
        defaultValue={options.filter((o) => o.selected)}
        placeholder={"Select a rank"}
        options={options}
        onChange={handleChange}
        isOptionDisabled={(option) => option.disabled}
        styles={colourStyles}
        isSearchable={false}
        isClearable
      />
    </Wrapper>
  );
};

export default Dropdown;
