// Helpers
// Function that will help me accomplish some goal for the project, not directly tied to a specific component.
// Helpers are project specific unlike utils which can be copied to other projects
export const getNumberWithOrdinal = (n: number): string => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getNumberFromOrdinal = (n: string): string => {
  const number = n.match(/\d+/);
  return number ? number[0] : "";
};
