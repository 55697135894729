import { useState, useEffect } from "react";
import { Wrapper } from "./StepperHeader.styles";
import Breadcrumbs from "components/base/Breadcrumbs/Breadcrumbs.view";
import Crumb from "components/base/Breadcrumbs/Crumb.view";

import { questions } from "../../logic";
import { IStepper } from "redux-components";
import { useSelector } from "react-redux";
import { selectStepper } from "redux-components/selectors/StepperSelector";
import { useActionCreators } from "hooks";

interface IStepperHeader {
  startRecommendation: boolean;
}

const StepperHeaderMobile = (props: IStepperHeader) => {
  const { startRecommendation } = props;
  const stepper: IStepper = useSelector(selectStepper);
  const [isMobile, setIsMobile] = useState(false);
  const { setStep } = useActionCreators();

  const handleResize = () => {
    if (window.innerWidth < 760) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const handleClick = async (id: string) => {
    await setStep(id);
  };

  return (
    <Wrapper>
      <Breadcrumbs>
        {questions.map((question, index) => {
          return (
            <Crumb
              isActive={
                startRecommendation
                  ? false
                  : stepper.activeQuestion.id === question.id
              }
              link="#"
              key={index}
              onClick={() => handleClick(question.id)}
            >
              {question.step}
            </Crumb>
          );
        })}
        <Crumb isActive={startRecommendation} link="#">
          {isMobile ? "Result" : "Recommendation"}
        </Crumb>
      </Breadcrumbs>
    </Wrapper>
  );
};

export default StepperHeaderMobile;
