import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  align-items: center;
  gap: 4rem;
  background: #f7f8f9;
  padding-top: 4rem;

  h1 {
    text-align: center;
    font-size: 2.2rem;
    line-height: 140%;
  }

  @media (min-width: 480px) {
    padding: 4rem 2rem 2rem 2rem;

    h1 {
      font-size: 3rem;
    }
  }

  @media (min-width: 1730px) {
    padding: 1rem;
    padding-top: 4rem;
  }
`;

export const QuestionTypeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 1500px) {
    width: 80%;
    gap: 2rem;
  }
`;

export const Choices = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
  flex-direction: column;
  border: 1px solid purple;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    max-width: 1200px;
    width: 90%;
  }
`;

export const QuestionText = styled.div`
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  line-height: 120%;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 480px) {
    flex-direction: row;
    gap: 4rem;
  }

  @media (min-width: 1550px) {
    width: 80%;
  }
`;
