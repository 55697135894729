type TRanking = {
  id: string;
  questionId: string;
  ranking: string[];
};

const ranking: TRanking[] = [
  {
    id: "6c9a6d8a-8e7c-4441-b998-436fe9d35698",
    questionId: "bc137eea-ed15-44e8-96dd-336c3e2ea75d",
    ranking: [
      "2a9ab8b0-0f9a-4533-a90c-d3d909bc99e7",
      "14a3bebe-042f-49a4-8a45-455b79732257",
      "a415d777-b44d-4aeb-bd27-dd49756753bb",
    ],
  },
  {
    id: "21227b24-8b87-4549-a8be-b3f985569f00",
    questionId: "bc137eea-ed15-44e8-96dd-336c3e2ea75d",
    ranking: [
      "a415d777-b44d-4aeb-bd27-dd49756753bb",
      "1f208e62-b111-4b04-bf9c-26ec1c72d894",
      "2a9ab8b0-0f9a-4533-a90c-d3d909bc99e7",
    ],
  },
];

export default ranking;
