import { Dispatch } from "redux";
import { RecommendationDispatchTypes } from "redux-components/action-dispatch-types";
import { ProductInterface } from "redux-components/interfaces";
import { RecommendationActionTypes } from "../action-types";

export const evaluateAnswers =
  (answers: string[]) =>
  async (dispatch: Dispatch<RecommendationDispatchTypes>, getState: any) => {
    dispatch({
      type: RecommendationActionTypes.EVALUATE,
      payload: answers,
    });
  };

export const setProductsRecommendation =
  (ids: ProductInterface[]) =>
  async (dispatch: Dispatch<RecommendationDispatchTypes>, getState: any) => {
    dispatch({
      type: RecommendationActionTypes.SET_PRODUCTS,
      payload: ids,
    });
  };

export const resetRecommendation =
  () =>
  async (dispatch: Dispatch<RecommendationDispatchTypes>, getState: any) => {
    dispatch({
      type: RecommendationActionTypes.RESET,
    });
  };
