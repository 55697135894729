import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import logger from "redux-logger";
import reducers from "./reducers";

const middlewares = [thunk];
/*let middlewares: any = [];

if (process.env.NODE_ENV === "development") {
  middlewares = [...middlewares, thunk, logger];
} else {
  middlewares = [...middlewares, thunk];
}*/

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
let persistor = persistStore(store);

export { store, persistor };
