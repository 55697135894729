import { CheckboxCardProps } from './CheckboxCard.props';
import { CheckboxOutline, CheckboxFill } from '../Icons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  Card,
  CardHeader,
  CardHeaderLabel,
  CardImage,
} from './CheckboxCard.styles';
import { useState } from 'react';

const CheckboxCard = ({
  id,
  label,
  imgUrl,
  isSelected,
  onChange,
}: CheckboxCardProps) => {
  const handleClick = () => {
    onChange(id);
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Card onClick={handleClick} isSelected={isSelected}>
      <CardHeader>
        <CardHeaderLabel>{label}</CardHeaderLabel>
        {isSelected ? (
          <CheckboxFill width={24} height={24} color="var(--color-red-light)" />
        ) : (
          <CheckboxOutline
            width={24}
            height={24}
            color="var(--color-red-light)"
          />
        )}
      </CardHeader>
      <CardImage>
        {imageLoaded ? null : (
          <Skeleton
            style={{
              width: 320,
              maxWidth: 320,
              height: 320,
            }}
          />
        )}

        <img
          style={{ display: imageLoaded ? 'block' : 'none', width: '100%' }}
          onLoad={() => setImageLoaded(true)}
          src={imgUrl}
          alt={id}
        />
      </CardImage>
    </Card>
  );
};

export default CheckboxCard;
