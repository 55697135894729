import styled from 'styled-components';

interface Props {
  style?: any;
  imgUrl?: string;
  isSelected?: boolean;
}

export const Card = styled.div<Props>`
  width: 100%;
  padding: 16px;
  background-color: #fff;
  border: 2px solid
    ${(props) => (props.isSelected ? ' var(--color-red-light)' : '#fff')};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  ${(props) =>
    props.isSelected ? 'touch-action: none; pointer-events: none;' : ''};

  @media (min-width: 1024px) {
    min-width: 200px;
  }

  @media (min-width: 1400px) {
    min-width: 250px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0 16px 0;
`;

export const CardHeaderLabel = styled.p`
  font-weight: bold;
  font-size: 16px;

  @media (min-width: 950px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

export const CardImage = styled.div<Props>`
  background-image: url(${(props) => (props.imgUrl ? props.imgUrl : '')});
  min-height: 100%;
  width: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* min-width: fit-content; */

  @media (min-width: 1300px) {
    max-height: 26rem;
    overflow: hidden;
  }
`;
