import styled from "styled-components";

interface Props {
  style?: any;
  imgUrl?: string;
  isSelected?: boolean;
}

export const Wrapper = styled.div<Props>`
  margin-bottom: 20px;
`;
