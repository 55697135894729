import React from 'react';
import { ButtonProps } from './Button.props';
import { FillButton, OutlineButton, GhostButton } from './Button.styles';

export const SIZES = {
  default: {
    '--padding': '.8rem 3.2rem',
    '--fontSize': 1.6 + 'rem',
  },
  small: {
    '--padding': '.8rem 1.6rem',
    '--fontSize': 1.4 + 'rem',
  },
  medium: {
    '--padding': '.8rem 4.0rem',
    '--fontSize': 1.6 + 'rem',
  },
  large: {
    '--padding': '1rem 5.4rem',
    '--fontSize': 1.6 + 'rem',
  },
};

const Button = (props: ButtonProps) => {
  const { label, size, variant, children } = props;
  const sizeStyle = size ? SIZES[size] : SIZES['default'];
  let ButtonVariant;

  if (variant === 'outlined') {
    ButtonVariant = OutlineButton;
  } else if (variant === 'ghost') {
    ButtonVariant = GhostButton;
  } else {
    ButtonVariant = FillButton;
  }

  return (
    <ButtonVariant {...props} style={sizeStyle}>
      {children ? children : label}
    </ButtonVariant>
  );
};

export default Button;
