import React from "react";
import CheckboxCard from "components/base/CheckboxCard/CheckboxCard";
import { useSelector } from "react-redux";

import { useActionCreators } from "hooks";
import { selectStepper } from "redux-components/selectors/StepperSelector";
import { IStepper } from "redux-components";
import { Wrapper } from "./Radio.styles";
import {
  computeAnsweredQuestions,
  getNextQuestionId,
  sortSelectedAnswers,
} from "redux-components/reducers/stepperReducer";

type Choice = {
  id: string;
  value: string;
  imageLink?: string;
};

interface IRadio {
  choices: Choice[];
}

const Radio = (props: IRadio) => {
  const { choices } = props;
  const current: IStepper = useSelector(selectStepper);
  const selectedAnswers = current.selectedAnswers;
  const {
    setStep,
    setAnswer,
    clearAnswers,
    computeAnswers,
    evaluateAnswers,
    setRecommendationPage,
  } = useActionCreators();

  const handleCheckbox = async (id: string) => {
    // This will fix the outdated selected answers in store
    const _selectedAnswers = sortSelectedAnswers([
      id,
      ...current.selectedAnswers,
    ]);

    // allow only one selection
    await clearAnswers(current.activeQuestion.id);
    await setAnswer(id);
    await computeAnswers(_selectedAnswers);

    const { skippedQuestions, totalQuestions, answeredQuestions } =
      computeAnsweredQuestions(_selectedAnswers);

    if (!current.activeQuestion.done) {
      // set a delay so that the user sees what is being selected
      setTimeout(async () => {
        const nextId = getNextQuestionId({
          id: current.activeQuestion.id,
          skippedQuestions,
        });
        if (nextId && nextId.length) {
          await setStep(nextId);
        } else {
          await evaluateAnswers(_selectedAnswers);
          // @TODO: Improve the condition
          if (totalQuestions !== answeredQuestions.length) {
            await evaluateAnswers(selectedAnswers);
          }
        }
      }, 300);
    }
  };

  return (
    <Wrapper>
      {choices.map((item: Choice) => (
        <CheckboxCard
          key={`card-${item.id}`}
          id={item.id}
          label={item.value}
          imgUrl={item.imageLink}
          isSelected={selectedAnswers.includes(item.id)}
          onChange={(id) => handleCheckbox(id)}
        />
      ))}
    </Wrapper>
  );
};

export default Radio;
