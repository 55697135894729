import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
/* RESET CSS STYLES */

/* 1. Use a more-intuitive box-sizing model. */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* 2. Remove default margin & padding */
* {
  margin: 0;
  padding: 0;
}

/* 3. Allow percentage-based heights and reset default 16px to 10px per */
html,
body {
  height: 100%;
  font-size: 62.5%; // Reset default 16px to 10px per 1 rem
  font-family: 'Montserrat', sans-serif;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-size: 1.6rem;
}

/* 6. Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* 7. Remove built-in form typography styles */
input,
button,
textarea,
select {
  font: inherit;
}

/* 8. Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5, 
h6 {
  overflow-wrap: break-word;
}

/* 9. Create a root stacking context */
#root,
#__next {
  isolation: isolate;
}

/* THEME */
:root {
  //SPACING
  --space-xs: .4rem;
  --space-sm: .8rem;
  --space-md: 1.6rem;
  --space-lg: 3.2rem;

  //COLORS
  --color-red-light: #e70000;
  --color-red-default: #bc2026;
  --color-red-dark: #511410;

  --color-neutral-white: #fff;
  --color-neutral-black: #000000;

  --color-green-default: #59B166;

  --color-gray-light1: #eaeaea;
  --color-gray-light2: #9d9fa2;
  --color-gray-light3: #F7F8F9;
  --color-gray-default: #55575b;
  --color-gray-dark: #373a47;

  //FONT FAMILY
  --font-montserrat: 'Montserrat';

  //FONT SIZE
  --font-size-4: .4rem;
  --font-size-8: .8rem;
  --font-size-12: 1.2rem;
  --font-size-16: 1.6rem;
  --font-size-24: 2.4rem;
  --font-size-32: 3.2rem;
  --font-size-40: 4.0rem;
  --font-size-48: 4.0rem;
  --font-size-54: 5.4rem;

  @media (min-width: 1024px) {
    --space-sm: 1.6rem;
    --space-md: 3.2rem;
  }
}
`;

export default GlobalStyles;
