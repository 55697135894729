import { Dispatch } from "redux";
import { ProductsActiontypes } from "../action-types";
import { config } from "redux-components/config/index";
import { ProductDispatchTypes } from "redux-components/action-dispatch-types/ProductDispatchTypes";
import axios from "axios";
import { ExternalProducts } from "logic/defaults";

export const fetchProducts =
  (ids: string[]) =>
  async (dispatch: Dispatch<ProductDispatchTypes>, getState: any) => {
    try {
      dispatch({
        type: ProductsActiontypes.FETCH,
      });

      const fetchProducts = ids.map((id: string) => {
        if (id.includes("-externalProduct")) {
          // External Products, pulled in file source
          return {
            data: [
              ExternalProducts.find((product) => product.external.id === id),
            ],
            status: 200,
          };
        } else {
          // Internal Products, fetched via API
          return axios.get(
            `https://jolly-tartan-fortnight.glitch.me/3dcart/products/${id}`,
            {
              headers: {
                "glitch-token": config.glitchToken,
              },
            }
          );
        }
      });

      const products: any = await Promise.all(fetchProducts);

      if (!products.length) {
        return [];
      }

      for (let i = 0; i < products.length; i++) {
        dispatch({
          type: ProductsActiontypes.SET,
          payload: products[i].data[0],
        });
      }
    } catch (error: any) {
      dispatch({
        type: ProductsActiontypes.ERROR,
        payload: error,
      });
    }
  };

export const setProductsToDisplay =
  (ids: string[] | boolean) =>
  async (dispatch: Dispatch<ProductDispatchTypes>, getState: any) => {
    dispatch({
      type: ProductsActiontypes.SET_PRODUCTS_TO_DISPLAY,
      payload: ids,
    });
  };

export const resetProducts =
  () => async (dispatch: Dispatch<ProductDispatchTypes>, getState: any) => {
    dispatch({
      type: ProductsActiontypes.RESET,
    });
  };
